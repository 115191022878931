<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-pa-none">
        <q-btn
          flat
          label="back"
          color="primary"
          @click="
            $router.push(
              '/penilaiantahfidz/' + selSiswa.value + '/' + $route.params.tipe
            )
          "
        ></q-btn>
      </q-card-section>
      <q-card-section class="q-gutter-md">
        <q-input
          v-if="selSiswa != null"
          outlined
          readonly
          v-model="selSiswa.label"
          label="Nama Siswa"
        ></q-input>
        <p class="row justify-between">
          <a>Nilai Sebelumnya</a>
          <a>: {{ ujian.nilai }}</a>
        </p>
        <q-input
          outlined
          stack-label
          :readonly="ujian.nilai >= kkm"
          type="number"
          v-model="nilai_remed"
          label="nilai remed"
        ></q-input>
      </q-card-section>
      <q-card-actions v-if="ujian.nilai < kkm" class="q-pa-none">
        <q-btn
          unelevated
          color="primary"
          class="full-width no-border-radius"
          @click="remed()"
          :disable="nilai_remed == null"
          >simpan</q-btn
        >
      </q-card-actions>
      <q-markup-table
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height : calc(100vh - 357px)"
      >
        <thead>
          <tr>
            <th>Nilai Lama</th>
            <th>Nilai Baru</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in listRemed" :key="i">
            <td>{{ val.nilai_asal }}</td>
            <td>{{ val.nilai }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      tab: "setoran",

      kkm: 80,
      tipe: "UTS",
      ujian: {
        nilai: null,
        id: null
      },
      nilai_remed: null,
      listRemed: [],

      date: null,

      selSiswa: null
    };
  },
  async mounted() {
    await this.getSiswa();
    await this.getnilaiujian();
  },
  methods: {
    async getnilaiujian() {
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getujian/${this.selSiswa.value}/${this.$route.params.tipe}`
      );
      if (resp.data.length > 0) {
        this.ujian = resp.data[0];
      }
      this.getListRemed();
    },
    async getListRemed() {
      if (this.ujian.id == null) return;
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getlistremed/${this.ujian.id}`
      );
      this.listRemed = resp.data;
    },
    async remed() {
      await this.$http.post(
        `/penilaian/tahfidz/remed/${this.ujian.id}/${this.ujian.nilai}/${this.nilai_remed}`
      );
      this.getnilaiujian();
    },
    async getSiswa() {
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getsingle/${this.$route.params.id}`
      );
      this.selSiswa = resp.data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
